import { useDrag } from "react-dnd";
import React, { useState } from "react";
import { useSurveyStore } from "./stores/useSurveyStore";

const DraggableItem = ({ item }) => {
    const { id, icon: Icon, name, type } = item;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "ITEM",
        item: { id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const [isHovered, setIsHovered] = useState(false);
    const { setDroppedItems, droppedItems } = useSurveyStore();
    return (
        <div
            className={
                "cursor-grab flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-xl " +
                (isHovered ? "bg-gray-100" : "")
            }
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onDoubleClick={() => {
                // console.log("Double click on item:", id);
                const timeStamp = new Date();
                setDroppedItems([
                    ...droppedItems,
                    { id: `sk-${timeStamp}`, name: `sk-${timeStamp}`, type },
                ]);
            }}
        >
            {Icon && <Icon />}{" "}
            <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-gray-600">
                {name}
            </p>
        </div>
    );
};

export default DraggableItem;
