import React, { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import RadioInput from "./RadioInput";
import CustomSelect from "./CustomSelect";
import { formatDateToDateTime } from "./functions";

const SettingsModalComponent = ({
    setIsModalOpen,
    modalContent,
    settings,
    setSettings,
    departments,
    usersFromProps = [],
}) => {
    console.log(settings);
    // console.log("departments", departments);

    const [users, setUsers] = useState([]);

    const [dataValue, setDataValue] = useState({
        startDate: null,
        endDate: null,
    });
    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        if (usersFromProps.length) {
            const users = usersFromProps.map((user) => ({
                value: {
                    id: user.id,
                    name: user.name,
                    department: user.deportament,
                },
                label: user.name,
            }));
            // console.log("users", users);

            setUsers(users);
        }
    }, [usersFromProps]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value);
        const updatedSettings = { ...settings };

        // Логика для регулярности опросов
        if (name === "surveyFrequency" || name === "notifications") {
            updatedSettings.regularity = {
                ...settings.regularity,
                [name === "surveyFrequency"
                    ? "makeSurveyRegular"
                    : "surveyNotificationsFrequency"]: value,
            };
            if (name === "surveyFrequency") {
                updatedSettings.is_regular = value;
            } else {
                updatedSettings.notification_frequency = value;
            }
        }

        // Логика для приватности опросов
        if (
            name === "surveyAccess" ||
            name === "viewOwnResults" ||
            name === "viewOthersResults"
        ) {
            updatedSettings.privacy = {
                ...settings.privacy,
                [name]: value,
            };

            // Дополнительные поля для отображения результатов
            if (name === "viewOwnResults") {
                updatedSettings.who_can_view_own = value;
            }

            if (name === "viewOthersResults") {
                updatedSettings.who_can_view_others = value;
            }
        }

        setSettings(updatedSettings);
    };

    const handleDataPickerChange = (newValue) => {
        setDataValue(newValue);
        if (newValue) {
            setSettings({
                ...settings,
                regularity: {
                    ...settings.regularity,
                    makeSurveyRegular: newValue.endDate,
                },
                is_regular: formatDateToDateTime(newValue.endDate),
            });
        }
    };

    // Функция для проверки, является ли строка датой
    function isValidDate(dateString) {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date);
    }

    // Функция для проверки, больше ли дата текущей
    function isFutureDate(dateString) {
        const date = new Date(dateString);
        const today = new Date();

        // Сравниваем только даты без учета времени
        return (
            isValidDate(dateString) &&
            date.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)
        );
    }

    // Использование в вашем компоненте
    const placeholderText = isFutureDate(settings.regularity.makeSurveyRegular)
        ? settings.regularity.makeSurveyRegular
        : "Указать дату";

    const handleCustomSelectChange = (selectedOption, prop1, prop2) => {
        // console.log("selectedOption:", selectedOption);
        // console.log("prop1:", prop1);
        // console.log("prop2:", prop2);

        if (!selectedOption.length) return;

        const values = Array.isArray(settings.privacy.surveyAccess)
            ? settings.privacy.surveyAccess
            : [];

        selectedOption.forEach((option) => values.push(option.value.id));
        // console.log("values", values);

        const updatedPrivacy = {
            ...settings.privacy,
            [`${prop1}${prop2}`]: values,
        };

        const getFilteredUsers = (condition) => {
            return users
                .filter((user) => condition(user))
                .map((user) => user.value.id)
                .join(",");
        };

        let whoCanTake, whoCanViewOwn, whoCanViewOthers;

        if (prop1 === "surveyAccess") {
            if (prop2 === "departments") {
                const filtredDepartments = departments.filter((d) =>
                    values.includes(d.value.id)
                );
                whoCanTake = getFilteredUsers((user) =>
                    filtredDepartments.some(
                        (d) =>
                            d?.value?.name?.toLowerCase() ===
                            user?.value?.department?.toLowerCase()
                    )
                );
            } else if (prop2 === "users") {
                whoCanTake = getFilteredUsers((user) =>
                    values.includes(user.value.id)
                );
            } else if (prop2 === "Exclude") {
                whoCanTake = getFilteredUsers(
                    (user) => !values.includes(user.value.id)
                );
            }
        } else if (prop1 === "viewOwnResults" && prop2 === "Exclude") {
            whoCanViewOwn = getFilteredUsers(
                (user) => !values.includes(user.value.id)
            );
        } else if (prop1 === "viewOthersResults" && prop2 === "Exclude") {
            whoCanViewOthers = getFilteredUsers(
                (user) => !values.includes(user.value.id)
            );
        }

        setSettings({
            ...settings,
            privacy: updatedPrivacy,
            ...(whoCanTake && { who_can_take: whoCanTake }),
            ...(whoCanViewOwn && { who_can_view_own: whoCanViewOwn }),
            ...(whoCanViewOthers && {
                who_can_view_others: whoCanViewOthers,
            }),
        });
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={handleOutsideClick}
        >
            <div
                className="flex flex-col justify-start items-start w-[555px] relative overflow-hidden gap-6 p-8 rounded-lg bg-white"
                style={{
                    boxShadow:
                        "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                }}
            >
                {modalContent === "regularity" && (
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-6">
                        <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37] mt-8 mb-8">
                            Сделать опрос регулярным
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-500">
                            Проводить опрос раз в....
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6">
                            <RadioInput
                                name="surveyFrequency"
                                value="1"
                                checked={
                                    settings.regularity.makeSurveyRegular ===
                                    "1"
                                }
                                onChange={handleChange}
                                label="Месяц"
                            />
                            <RadioInput
                                name="surveyFrequency"
                                value="3"
                                checked={
                                    settings.regularity.makeSurveyRegular ===
                                    "3"
                                }
                                onChange={handleChange}
                                label="3 месяца"
                            />
                            <RadioInput
                                name="surveyFrequency"
                                value="6"
                                checked={
                                    settings.regularity.makeSurveyRegular ===
                                    "6"
                                }
                                onChange={handleChange}
                                label="6 месяцев"
                            />
                        </div>

                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#e02424]">
                            Выбрать точную дату
                        </p>
                        <div className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative">
                            <p className="flex-grow-0 flex-shrink-0 w-[250px] text-base text-left text-gray-600">
                                Следующий опрос провести...
                            </p>
                            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-lg border border-gray-200">
                                <Datepicker
                                    primaryColor={"red"}
                                    placeholder={placeholderText}
                                    containerClassName="border-0 flex flex-row-reverse items-center justify-center"
                                    asSingle={true}
                                    useRange={false}
                                    popoverDirection="up"
                                    value={dataValue}
                                    inputClassName="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37] border-0 focus:border-0 focus:ring-0 focus:outline-none"
                                    toggleClassName="p-0"
                                    onChange={(newValue) =>
                                        handleDataPickerChange(newValue)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {modalContent === "notifications" && (
                    <>
                        <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                            Как часто приходят уведомления о опросе
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                            <RadioInput
                                name="notifications"
                                value="1"
                                checked={
                                    settings.regularity
                                        .surveyNotificationsFrequency === "1"
                                }
                                onChange={handleChange}
                                label="При создании опроса"
                            />
                            <RadioInput
                                name="notifications"
                                value="2"
                                checked={
                                    settings.regularity
                                        .surveyNotificationsFrequency === "2"
                                }
                                onChange={handleChange}
                                label="При создании и за 1 день до
                                                окончания"
                            />
                            <RadioInput
                                name="notifications"
                                value="everyday"
                                checked={
                                    settings.regularity
                                        .surveyNotificationsFrequency ===
                                    "everyday"
                                }
                                onChange={handleChange}
                                label="Присылать каждый день пока опрос
                                                не пройден"
                            />
                        </div>
                    </>
                )}
                {modalContent === "surveyAccess" && (
                    <>
                        <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                            Кто может проходить опрос
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                            <RadioInput
                                name="surveyAccess"
                                value="all"
                                checked={
                                    settings.privacy.surveyAccess === "all"
                                }
                                onChange={handleChange}
                                label="Все"
                            />
                            <RadioInput
                                name="surveyAccess"
                                value="departments"
                                checked={
                                    settings.privacy.surveyAccess ===
                                    "departments"
                                }
                                onChange={handleChange}
                                label="Выбрать отделы"
                            />
                            {settings.privacy.surveyAccess ===
                                "departments" && (
                                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[350px] gap-2 px-px pb-1 rounded-lg">
                                    <CustomSelect
                                        options={departments}
                                        placeholder="Выберите отдел"
                                        onChange={(value) =>
                                            handleCustomSelectChange(
                                                value,
                                                "surveyAccess",
                                                "departments"
                                            )
                                        }
                                        value={settings.privacy.surveyAccessdepartments.map(
                                            (el) =>
                                                departments.find(
                                                    (department) =>
                                                        department.value.id ===
                                                        el
                                                )
                                        )}
                                    />
                                </div>
                            )}
                            <RadioInput
                                name="surveyAccess"
                                value="users"
                                checked={
                                    settings.privacy.surveyAccess === "users"
                                }
                                onChange={handleChange}
                                label="Выбрать людей"
                            />
                            {settings.privacy.surveyAccess === "users" && (
                                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[350px] gap-2 px-px pb-1 rounded-lg">
                                    <CustomSelect
                                        options={users}
                                        placeholder="Выберите людей"
                                        onChange={(value) =>
                                            handleCustomSelectChange(
                                                value,
                                                "surveyAccess",
                                                "users"
                                            )
                                        }
                                        value={settings.privacy.surveyAccessusers.map(
                                            (el) =>
                                                users.find(
                                                    (user) =>
                                                        user.value.id === el
                                                )
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#e02424]">
                            Добавить исключения
                        </p>
                        <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Не может проходить опрос
                            </p>
                            <CustomSelect
                                options={users}
                                placeholder="Выберите людей"
                                onChange={(value) => {
                                    handleChange({
                                        target: {
                                            name: "surveyAccess",
                                            value: "exclude",
                                        },
                                    });
                                    handleCustomSelectChange(
                                        value,
                                        "surveyAccess",
                                        "Exclude"
                                    );
                                }}
                                menuPlacement="top"
                                value={settings.privacy.surveyAccessExclude.map(
                                    (el) =>
                                        users.find(
                                            (user) => user.value.id === el
                                        )
                                )}
                            />
                        </div>
                    </>
                )}
                {modalContent === "viewOwnResults" && (
                    <>
                        <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                            Кто может просматривать свой результат
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                            <RadioInput
                                name="viewOwnResults"
                                value="all"
                                checked={
                                    settings.privacy.viewOwnResults === "all"
                                }
                                onChange={handleChange}
                                label="Все"
                            />
                            <RadioInput
                                name="viewOwnResults"
                                value="rukovoditel"
                                checked={
                                    settings.privacy.viewOwnResults ===
                                    "rukovoditel"
                                }
                                onChange={handleChange}
                                label="Руководители"
                            />

                            <RadioInput
                                name="viewOwnResults"
                                value="admin"
                                checked={
                                    settings.privacy.viewOwnResults === "admin"
                                }
                                onChange={handleChange}
                                label="Админы"
                            />
                        </div>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#e02424]">
                            Добавить исключения
                        </p>
                        <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Не может просматривать <br />
                                свой результат
                            </p>
                            <CustomSelect
                                options={users}
                                placeholder="Выберите людей"
                                onChange={(value) => {
                                    handleChange({
                                        target: {
                                            name: "viewOwnResults",
                                            value: "exclude",
                                        },
                                    });
                                    handleCustomSelectChange(
                                        value,
                                        "viewOwnResults",
                                        "Exclude"
                                    );
                                }}
                                menuPlacement="top"
                                value={settings.privacy.viewOwnResultsExclude.map(
                                    (el) =>
                                        users.find(
                                            (user) => user.value.id === el
                                        )
                                )}
                            />
                        </div>
                    </>
                )}

                {modalContent === "viewOthersResults" && (
                    <>
                        <p className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                            Кто может просматривать чужие результаты
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                            <RadioInput
                                name="viewOthersResults"
                                value="all"
                                checked={
                                    settings.privacy.viewOthersResults === "all"
                                }
                                onChange={handleChange}
                                label="Все"
                            />
                            <RadioInput
                                name="viewOthersResults"
                                value="rukovoditel"
                                checked={
                                    settings.privacy.viewOthersResults ===
                                    "rukovoditel"
                                }
                                onChange={handleChange}
                                label="Руководители"
                            />

                            <RadioInput
                                name="viewOthersResults"
                                value="admin"
                                checked={
                                    settings.privacy.viewOthersResults ===
                                    "admin"
                                }
                                onChange={handleChange}
                                label="Админы"
                            />
                        </div>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#e02424]">
                            Добавить исключения
                        </p>
                        <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Не может просматривать <br />
                                чужие результаты
                            </p>
                            <CustomSelect
                                options={users}
                                placeholder="Выберите людей"
                                onChange={(value) => {
                                    handleChange({
                                        target: {
                                            name: "viewOthersResults",
                                            value: "exclude",
                                        },
                                    });
                                    handleCustomSelectChange(
                                        value,
                                        "viewOthersResults",
                                        "Exclude"
                                    );
                                }}
                                menuPlacement="top"
                                value={settings.privacy.viewOthersResultsExclude.map(
                                    (el) =>
                                        users.find(
                                            (user) => user.value.id === el
                                        )
                                )}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SettingsModalComponent;
