import { create } from "zustand";
import RadioSVG from "../icons/RadioSVG";
import CheckBoxSVG from "../icons/CheckBoxSVG";
import LongTextIconSVG from "../icons/LongTextIconSVG";
import SmallTextIconSVG from "../icons/SmallTextIconSVG";
import SwitchToggleIconSVG from "../icons/SwitchToggleIconSVG";
import ArrowsUpDownIconSVG from "../icons/ArrowsUpDownIconSVG";
import FileIconSVG from "../icons/FileIconSVG";
import StarIconSVG from "../icons/StarIconSVG";

const defaultSettings = {
    privacy: {
        surveyAccess: "all",
        surveyAccessdeparts: [],
        surveyAccessPeople: [],
        surveyAccessExclude: [],
        viewOwnResults: "all",
        viewOwnResultsExclude: [],
        viewOthersResults: "admin",
        viewOthersResultsExclude: [],
    },
    regularity: {
        surveyAvailableFrom: "с этого момента",
        surveyAvailableTo: "по...",
        makeSurveyRegular: false,
        surveyNotificationsFrequency: "1",
    },
    status: "active",
    end_date: "",
    who_can_take: "",
    who_can_view_own: "",
    who_can_view_others: "",
    survey_start: "",
    survey_end: "",
    is_regular: false,
    notification_frequency: 0,
};

const items = [
    {
        id: 0,
        name: "Несколько вариантов",
        icon: CheckBoxSVG,
        type: "multiple-choice",
    },
    { id: 1, name: "Один вариант", icon: RadioSVG, type: "single-choice" },
    {
        id: 2,
        name: "Короткий текст",
        icon: SmallTextIconSVG,
        type: "short-text",
    },
    { id: 3, name: "Длинный текст", icon: LongTextIconSVG, type: "long-text" },
    { id: 4, name: "Да/Нет", icon: SwitchToggleIconSVG, type: "yes-no" },
    { id: 5, name: "Оценка по шкале", icon: StarIconSVG, type: "rating" },
    { id: 6, name: "Файл", icon: FileIconSVG, type: "file" },
    {
        id: 7,
        name: "Выпадающий список",
        icon: ArrowsUpDownIconSVG,
        type: "dropdown",
    },
];

export const useSurveyStore = create((set, get) => ({
    droppedItems: [],
    surveyTitle: "Новый опрос",
    surveyId: null,
    settings: defaultSettings,
    items,
    surveyJson: {
        survey_id: "",
        title: "Новый опрос",
        privacy: JSON.stringify(defaultSettings.privacy),
        regularity: JSON.stringify(defaultSettings.regularity),
        cover: null,
        questions: [],
        elements: [],
        user_id: 1,
        is_admin: 1,
    },

    setSurveyJson: (newSurveyJson) => set({ surveyJson: newSurveyJson }),

    getSurveyJson: () => {
        const currentSurveyJson = get().surveyJson;
        return currentSurveyJson;
    },

    setDroppedItems: (newItems) =>
        set((state) => {
            // console.log("setDroppedItems", newItems);

            // Обновляем вопросы согласно порядку в newItems
            const newQuestions = newItems.map((newItem) => {
                // Находим вопрос в текущем состоянии, который соответствует новому элементу
                const existingQuestion = state.surveyJson?.questions?.find(
                    (question) => question.id === newItem.id
                );
                // Если вопрос существует, то обновляем его, иначе используем новый элемент как есть
                return {
                    ...existingQuestion,
                    ...newItem,
                };
            });

            const updatedSurveyJson = {
                ...state.surveyJson,
                questions: newQuestions, // Обновленные вопросы в новом порядке
                elements: newQuestions, // Если элементы тоже должны обновляться
            };

            return { droppedItems: newItems, surveyJson: updatedSurveyJson };
        }),

    setSurveyTitle: (newTitle) =>
        set((state) => {
            const updatedSurveyJson = {
                ...state.surveyJson,
                title: newTitle,
            };
            return { surveyTitle: newTitle, surveyJson: updatedSurveyJson };
        }),

    setSurveyId: (newId) =>
        set((state) => {
            const updatedSurveyJson = {
                ...state.surveyJson,
                survey_id: newId,
            };
            return { surveyId: newId, surveyJson: updatedSurveyJson };
        }),

    setSettings: (newSettings) =>
        set((state) => {
            const updatedSurveyJson = {
                ...state.surveyJson,
                privacy: JSON.stringify(newSettings.privacy),
                regularity: JSON.stringify(newSettings.regularity),
            };
            return { settings: newSettings, surveyJson: updatedSurveyJson };
        }),

    duplicateQuestion: (item, index) =>
        set((state) => {
            // console.log("surveyJson in duplicateQuestion", state.surveyJson);
            // console.log("item in duplicateQuestion", item);
            // console.log("index in duplicateQuestion", index);

            const timeStamp = Date.now();

            const required = JSON.parse(item.required);
            const newElement = {
                ...item,
                id: `sk-${timeStamp}`,
                name: `sk-${timeStamp}`,
                required,
            };

            const newItems = [...state.droppedItems];
            newItems.splice(index + 1, 0, newElement); // Вставляем дубликат после оригинала

            const updatedSurveyJson = {
                ...state.surveyJson,
                questions: newItems,
                elements: newItems,
            };
            // console.log(
            //     "updatedSurveyJson in duplicateQuestion",
            //     updatedSurveyJson
            // );

            return { droppedItems: newItems, surveyJson: updatedSurveyJson };
        }),

    removeQuestion: (index) =>
        set((state) => {
            const newItems = state.droppedItems.filter((_, i) => i !== index);
            const newQuestions = state.surveyJson.questions.filter(
                (_, i) => i !== index
            );

            const updatedSurveyJson = {
                ...state.surveyJson,
                questions: newQuestions,
                elements: newQuestions,
            };

            return { droppedItems: newItems, surveyJson: updatedSurveyJson };
        }),
}));
