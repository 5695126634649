import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSurveyStore } from "./stores/useSurveyStore";

const ConditionsModalComponent = ({
    setIsModalOpen,
    conditionsSettingsFromProps,
    handleConditionComplete,
}) => {
    const { surveyJson, setSurveyJson } = useSurveyStore();
    const conditionsSettings = surveyJson?.elements.find(
        (el) => el.id === conditionsSettingsFromProps?.id
    );
    // console.log("conditionsSettings", conditionsSettings);
    // console.log("surveyJson in ConditionsModalComponent", surveyJson);

    const questions = surveyJson?.elements || surveyJson?.questions || [];
    const [selectedOptions, setSelectedOptions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.option || []
    );
    const [selectedQuestions, setSelectedQuestions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.question || []
    );
    const [selectedConditions, setSelectedConditions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.condition || []
    );
    const [operators, setOperators] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.operators
            ? [
                  { value: "doesn't matter" },
                  ...parseExpression(
                      conditionsSettings?.visibleIf
                  )?.operators.map((o) => ({ value: o })),
              ]
            : [{ value: "doesn't matter" }]
    );
    // console.log("selectedQuestions", selectedQuestions);
    // console.log("selectedConditions", selectedConditions);
    // console.log("selectedOptions", selectedOptions);
    // console.log("operators", operators);

    const [conditionMode, setConditionMode] = useState(
        conditionsSettings?.visibleIf
    );
    const [canAddCondition, setCanAddCondition] = useState(false);

    useEffect(() => {
        setCanAddCondition(false);
        if (
            (selectedQuestions?.length,
            selectedConditions?.length,
            selectedOptions?.length,
            operators?.length)
        ) {
            // console.log("selectedQuestions", selectedQuestions);
            // console.log("selectedConditions", selectedConditions);
            // console.log("selectedOptions", selectedOptions);
            // console.log("operators", operators);
            let canComplete = false;
            selectedQuestions.forEach((question, index) => {
                if (
                    question.value &&
                    selectedConditions[index]?.value &&
                    selectedOptions[index]?.value &&
                    operators[index]?.value
                ) {
                    canComplete = true;
                } else {
                    canComplete = false;
                }
            });
            // console.log("canComplete", canComplete);

            if (canComplete) {
                handleConditionComplete(
                    conditionsSettings,
                    selectedQuestions,
                    selectedConditions,
                    selectedOptions,
                    operators
                );
                setCanAddCondition(true);
            }
        }
    }, [selectedQuestions, selectedConditions, selectedOptions]);

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsModalOpen(false);
        }
    };

    function getFormattedQuestionsForSelect() {
        const newQuestions = questions
            ?.filter((q, index) => q.id !== conditionsSettings.id)
            .map((question) => ({
                ...question,
                value: question.title,
                label: question.title,
                choices:
                    question.choices?.map((choice) => ({
                        value: choice,
                        label: choice,
                    })) || [],
                rateValues:
                    question.rateValues?.map((choice) => ({
                        value: choice,
                        label: choice,
                    })) || [],
            }));
        return newQuestions;
    }

    function getFormattedOptionsForSelect(question) {
        const obj = {
            choices:
                question.choices?.map(
                    (choice) =>
                        typeof choice === "object" && choice !== null
                            ? {
                                  value:
                                      "value" in choice ? choice.value : choice,
                                  label:
                                      "label" in choice ? choice.label : choice,
                              }
                            : { value: choice, label: choice } // Если choice — строка, используем её как value и label
                ) || [],
            rateValues:
                question.rateValues?.map(
                    (choice) =>
                        typeof choice === "object" && choice !== null
                            ? {
                                  value:
                                      "value" in choice ? choice.value : choice,
                                  label:
                                      "label" in choice ? choice.label : choice,
                              }
                            : { value: choice, label: choice } // Аналогично для rateValues
                ) || [],
        };
        console.log("obj", obj);
        return obj;
    }

    function parseExpression(expression) {
        if (!expression) {
            return null;
        }

        // Регулярное выражение для захвата всех условий {question} <> 'value' и операторов and/or
        const regex = /\{(.+?)\}\s*(<>|=)\s*'(.*?)'\s*(and|or)?/g;

        let match;
        const questions = [];
        const conditions = [];
        const options = [];
        const operators = [];

        // Проходим по всем совпадениям в строке
        while ((match = regex.exec(expression)) !== null) {
            const [_, name, condition, option, operator] = match;

            // Добавляем извлеченные данные в соответствующие массивы
            questions.push({
                ...getQustionByName(name.trim()),
                value: getQustionByName(name.trim())?.title,
                label: getQustionByName(name.trim())?.title,
            });

            conditions.push({
                value: condition.trim(),
                label: condition.trim() === "=" ? "равно" : "не равно",
            });

            options.push({
                value: option.trim(),
                label: option.trim(),
            });

            // Если найден оператор and/or, добавляем его в массив operators
            if (operator) {
                operators.push(operator.trim());
            }
        }

        // Если хотя бы одно условие найдено
        if (questions.length > 0) {
            return {
                question: questions,
                condition: conditions,
                option: options,
                operators: operators, // Массив операторов and/or
            };
        }

        // Если формат не совпадает, возвращаем null
        return null;
    }

    function getQustionByName(name) {
        return questions.find((question) => question.name === name);
    }

    function getActiveClass(always = false) {
        if (conditionMode) {
            return always ? "bg-white" : "bg-[#fdf2f2]";
        } else return !always ? "bg-white" : "bg-[#fdf2f2]";
    }

    function handleConditionModeToggle(mode) {
        setConditionMode(mode);
        if (mode === false) {
            // console.log(conditionsSettings);
            const updatedSurveyJson = { ...surveyJson };
            const questions = updatedSurveyJson.elements || [];
            questions.forEach((q) => {
                if (q.id === conditionsSettings.id) {
                    q.visibleIf = "";
                }
            });
            setSurveyJson(updatedSurveyJson);
        }
    }

    function handleConditionChange(func, e, index) {
        func((prevState) => {
            // console.log(prevState, e, index);
            if (index === prevState.length) {
                return [...prevState, e];
            }
            return (prevState.length > 0 ? prevState : [{}]).map((item, _i) => {
                if (_i === index) {
                    return e;
                }
                return item;
            });
        });
    }

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={handleOutsideClick}
        >
            <div
                className="flex flex-col justify-start items-start relative overflow-visible gap-6 p-12 rounded-lg bg-white"
                style={{
                    boxShadow:
                        "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                }}
            >
                <p className="flex-grow-0 flex-shrink-0 text-xl text-left text-[#1f2a37]">
                    <span className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                        Вопрос «{conditionsSettings?.title}»
                    </span>
                </p>
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                    <p className="flex-grow-0 flex-shrink-0 w-[121px] text-base text-left text-gray-600">
                        будет показан
                    </p>
                    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 space-x-[-1px] rounded-md bg-white">
                        <div
                            role="button"
                            className={
                                "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden pl-4 pr-[18px] py-2 rounded-tl-md rounded-bl-md border border-gray-200 " +
                                getActiveClass(true)
                            }
                            onClick={() => {
                                handleConditionModeToggle(false);
                            }}
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                Всегда
                            </p>
                        </div>
                        <div
                            role="button"
                            className={
                                "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden px-[17px] py-2 rounded-tr-md rounded-br-md border border-gray-200 " +
                                getActiveClass(false)
                            }
                            onClick={() => {
                                handleConditionModeToggle(true);
                            }}
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                При условии
                            </p>
                        </div>
                    </div>
                </div>
                {conditionMode && (
                    <>
                        {(selectedQuestions.length > 0
                            ? selectedQuestions
                            : [{}]
                        ).map((q, index) => (
                            <div
                                key={index}
                                className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-1"
                            >
                                {/* {console.log("question", q)} */}
                                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
                                    {index > 0 && (
                                        <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 space-x-[-1px] rounded-md bg-white">
                                            <div
                                                role="button"
                                                className={
                                                    "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden pl-4 pr-[18px] py-2 rounded-tl-md rounded-bl-md border border-gray-200 " +
                                                    getActiveClass(
                                                        operators[index]
                                                            ?.value !== "and" //немного криво
                                                    )
                                                }
                                                onClick={() =>
                                                    handleConditionChange(
                                                        setOperators,
                                                        {
                                                            value: "and",
                                                            label: "и",
                                                        },
                                                        index
                                                    )
                                                }
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                                    и
                                                </p>
                                            </div>
                                            <div
                                                role="button"
                                                className={
                                                    "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden px-[17px] py-2 rounded-tr-md rounded-br-md border border-gray-200 " +
                                                    getActiveClass(
                                                        operators[index]
                                                            ?.value !== "or" //немного криво
                                                    )
                                                }
                                                onClick={() =>
                                                    handleConditionChange(
                                                        setOperators,
                                                        {
                                                            value: "or",
                                                            label: "или",
                                                        },
                                                        index
                                                    )
                                                }
                                            >
                                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                                    или
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <Select
                                        options={getFormattedQuestionsForSelect()}
                                        onChange={(e) =>
                                            handleConditionChange(
                                                setSelectedQuestions,
                                                e,
                                                index
                                            )
                                        }
                                        value={q}
                                    />
                                    <Select
                                        options={[
                                            { value: "=", label: "равно" },
                                            { value: "<>", label: "не равно" },
                                        ]}
                                        onChange={(e) =>
                                            handleConditionChange(
                                                setSelectedConditions,
                                                e,
                                                index
                                            )
                                        }
                                        value={selectedConditions[index]}
                                    />
                                    {/* {console.log("choices", q?.choices)} */}
                                    {q?.choices && q?.choices.length > 0 && (
                                        <Select
                                            options={
                                                Array.isArray(
                                                    getFormattedOptionsForSelect(
                                                        q
                                                    ).choices
                                                )
                                                    ? getFormattedOptionsForSelect(
                                                          q
                                                      ).choices
                                                    : q.choices
                                            }
                                            onChange={(e) =>
                                                handleConditionChange(
                                                    setSelectedOptions,
                                                    e,
                                                    index
                                                )
                                            }
                                            value={
                                                parseExpression(
                                                    conditionsSettings?.visibleIf
                                                )?.option
                                            }
                                        />
                                    )}

                                    {q.type === "rating" &&
                                        q?.rateValues &&
                                        q?.rateValues.length > 0 && (
                                            <>
                                                <Select
                                                    options={
                                                        Array.isArray(
                                                            getFormattedOptionsForSelect(
                                                                q
                                                            ).rateValues
                                                        )
                                                            ? getFormattedOptionsForSelect(
                                                                  q
                                                              ).rateValues
                                                            : q.rateValues
                                                    }
                                                    onChange={(e) =>
                                                        handleConditionChange(
                                                            setSelectedOptions,
                                                            e,
                                                            index
                                                        )
                                                    }
                                                    value={
                                                        selectedOptions[index]
                                                    }
                                                />
                                            </>
                                        )}
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-lg border border-gray-200">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path d="M8 8H4H8Z" fill="#1F2A37"></path>
                                <path
                                    d="M8 4V8M8 8V12M8 8H12M8 8H4"
                                    stroke="#111928"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                            <p
                                role="button"
                                className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37]"
                                onClick={() =>
                                    setSelectedQuestions((prevState) => [
                                        ...prevState,
                                        {},
                                    ])
                                }
                            >
                                Добавить условие
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConditionsModalComponent;
